@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Lexend+Deca:wght@600;700&family=Ubuntu:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Fonts */
/* font-family: 'Inter', sans-serif; */
/* font-family: 'Ubuntu', sans-serif; */
/* font-family: 'Lexend Deca', sans-serif; */


/* :root.light, 
:root {
  --header-background: #071013;
  --header-contrastColor: #75ABBC;
  --hero-background: #395E66;
  --header-text: #F0F8FF;
} */


/* ============================ */
/* ========== Logo ============ */
/* ============================ */
.logo {
  font-family: 'Lexend Deca', sans-serif;
  font-size: 3rem;
  
}

.tilt {
  display: inline-block;
  position: absolute;
  top: 25px;
  left: 40px;
  transform: scale(1.25);
  color: var(--header-constrastColor, #75ABBC);
}

/* ========================================== */
/* ========== Home Page Image Grid ========== */
/* ========================================== */

.gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-rows: repeat(4,); */
    grid-template-rows: auto auto;
    grid-gap: 5px;
    padding: 5px;
    background-color: var(--hero-background, #395E66);
    margin-top: 60px;
}

.gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.span-1 {
  grid-column: span 1;
  grid-row: span 1;
}

.span-2 {
  grid-column: span 2;
  grid-row: span 1;
}

.span-3 {
  grid-column: span 2;
  grid-row: span 2;
}
