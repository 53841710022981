@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Lexend+Deca:wght@600;700&family=Ubuntu:wght@300;400;500;700&display=swap');

/* ======== Fonts ======== */
/* font-family: 'Inter', sans-serif; */
/* font-family: 'Ubuntu', sans-serif; */
/* font-family: 'Lexend Deca', sans-serif; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: var(--header-text, #F0F8FF);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

:root.light,
:root {
  --header-background: #071013;
  --header-contrastColor: #75ABBC;
  --hero-background: #395E66;
  --hero-secondaryText: #C1AE7C;
  --header-text: #F0F8FF;
  --box-shadow: -1px 0px 24px 8px rgba(0,0,0,0.51);
}

a {
  text-decoration: none;
  text-transform: uppercase;
  color: var(--heaader-text, #F0F8FF);
} 

p {
  line-height: 1.6;
}

.sectionMargin {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.current-page {
  list-style: none;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: .3em;
  text-decoration-color: var(--header-text, #F0F8FF);
  text-transform: uppercase;
  margin-left: 2em;
  color: var(--header-text, #F0F8FF);
}

.link {
  list-style: none;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: 2em;
  color: var(--header-text, #F0F8FF);
  }

  .carousel:hover .slide .legend {
    opacity: 1;
  }
